import React from "react";

function article_1() {
	return {
		date: "30 Sep 2024",
		title: "SOLID Prensipleri",
		description:
			"Yazılım geliştirmede kodun esnek, sürdürülebilir ve test edilebilir olmasını sağlayan beş temel ilke SOLID.",
		keywords: [
			"SOLID",
			"Yazılım Geliştirme",
		],
		link: "https://medium.com/@ismetkync/solid-prensipleri-e33cce4b35f2",
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Content of article 2</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "14 Oct 2024",
		title: "Closure Nedir?",
		description:
			"Merhaba, bu yazıda JavaScript mülakatlarında sıklıkla karşımıza gelen konulardan birine, closure kavramına göz atacağız.",
		keywords: [
			"JavaScript",
			"Closure",
			"Sofware Development",
			"Js Interview",

		],
		link: "https://medium.com/@ismetkync/closure-nedir-4b1eb7177598",
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Content of article 1</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}

function article_3() {
	return {
		date: "3 Jul 2022",
		title: "Keras ile Görüntü Sınıflandırma (Image Classification)",
		description:
			"TensorFlow ve Keras kullanarak bir görüntü sınıflandırma modeli oluşturma. Sinir ağları ve CNN'ler hakkında temel bilgiler. Veri hazırlama, model oluşturma ve eğitme adımları",
		keywords: [
			"Neural Network",
			"Konvolüsyonel Sinir Ağları ",
			"Görsel Sınıflandırma",

		],
		link: "https://medium.com/@ismetkync/keras-ile-g%C3%B6r%C3%BCnt%C3%BC-s%C4%B1n%C4%B1fland%C4%B1rma-image-classification-b447651e9a7a",
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Content of article 1</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}

const myArticles = [article_2, article_1, article_3]

export default myArticles;
