const INFO = {
	main: {
		title: "İsmet Ömer Koyuncu",
		name: "İsmet Ömer Koyuncu",
		email: "ismetomerkoyuncu@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/iomerkoyuncu",
		github: "https://github.com/iomerkoyuncu",
		linkedin: "https://linkedin.com/in/ismetomerkoyuncu",
		instagram: "https://instagram.com/iomerkoyuncu",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Hi, I'm İsmet Ömer Koyuncu",
		description:
			"A passionate software developer with 2 years of experience in creating web applications. Proficient in a wide array of modern technologies and frameworks, I specialize in crafting functional user interfaces. My dedication to staying updated		with the latest industry trends and best practices allows me to deliver robust and visually appealing solutions. For more information, check out my about page or my articles. If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
	},

	about: {
		title: "Hi! I’m İsmet Ömer,",
		description:
			"I am a software developer with over 2 years of experience developing web applications. Experienced in a wide range of modern technologies and frameworks, I am experienced in creating user interfaces. I'm enthusiastic about new technologies, I think continuous learning is part of engineering. During my engineering education, I had the opportunity to do many theoretical and practical projects in different technologies.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "website-to-file",
			description:
				"Node.js library to capture image/pdf from url.",
			logo: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-original-wordmark.svg",
			linkText: "View Project",
			link: "https://github.com/iomerkoyuncu/website-to-file",
		},
		{
			title: "ProductApp",
			description:
				"Backend for ProductApp with Onion Architechture and CQRS Design Pattern",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/csharp/csharp.png",
			linkText: "View Project",
			link: "https://github.com/iomerkoyuncu/ProductApp",
		},

		{
			title: "ProductApp React",
			description:
				"Frontend for ProductApp React.js & Redux",
			logo: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original.svg",
			linkText: "View Project",
			link: "https://github.com/iomerkoyuncu/productapp-react",
		},

		{
			title: "SurveyApp",
			description:
				"After the login process is completed, surveys can be created, previously created surveys can be edited and survey responses can be viewed.",
			logo: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-original-wordmark.svg",
			linkText: "View Project",
			link: "https://github.com/iomerkoyuncu/survey-app",
		},

		{
			title: "RealTimeTranslating",
			description:
				"React Native App which scans the words in real time and translates it to desired language (en - tr, tr - en)",
			logo: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original-wordmark.svg",
			linkText: "View Project",
			link: "https://github.com/iomerkoyuncu/RealTimeTranslating",
		},

		{
			title: "Weather Classification",
			description:
				"Image Classification with Keras",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/iomerkoyuncu/weather-classification",
		},
		{
			title: "CRUD Angular",
			description:
				"CRUD Application using Angular 14",
			logo: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/angularjs/angularjs-original.svg",
			linkText: "View Project",
			link: "https://github.com/iomerkoyuncu/crud-angular",
		},
		{
			title: "Hayat Bilgisi Forum",
			description:
				"Web Tasarım ve Geliştirme",
			logo: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/wordpress/wordpress-original.svg",
			linkText: "View Project",
			link: "https://hayatbilgisiforum.com/",
		},
		{
			title: "Rast Makine",
			description:
				"Web Tasarım ve Geliştirme",
			logo: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original.svg",
			linkText: "View Project",
			link: "https://rastmakine.com/",
		},
		{
			title: "OceanDrip",
			description:
				"Web Tasarım ve Geliştirme",
			logo: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/wordpress/wordpress-original.svg",
			linkText: "View Project",
			link: "https://oceandrip.com.tr/",
		},
	],
};

export default INFO;
